import { css } from "@emotion/react";
import { color } from "src/constants";

const style = Object.freeze({
  container: css`
    h2,
    h3 {
      margin-top: 32px;
      margin-bottom: 24px;
    }

    h2 {
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 32px;
      letter-spacing: 1px;
    }

    h3 {
      font-size: 1.2rem;
      letter-spacing: 1px;
    }

    h4 {
      font-size: 1.1rem;
      letter-spacing: 0.8px;
    }

    h4:before {
      content: '・'
    }

    .get-started {
      display: block;
      font-weight: bold;
      background: ${color.primary};
      border-radius: 32px;
      padding: 16px 32px;
      color: ${color.fontColor};
      text-align: center;
      max-width: 200px;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .mv-16 {
      margin-bottom: 16px !important;
      margin-top: 16px !important;
    }

    .mv-32 {
      margin-bottom: 32px !important;
      margin-top: 32px !important;
    }

    .spacer-32 {
      height: 32px;
    }

    .spacer-64 {
      height: 64px;
    }


    .card-link {
      display: block;
      border: 1px solid ${color["gray-1"]};
      box-shadow: 1px 3px 10px 2px #44444430;
      padding: 16px;
      margin-bottom: 8px;
      color: ${color.fontColor};
      transition: all 0.2s linear;
    }

    .card-link:before {
      display: inline-block;
      height: 0.3rem;
      width: 0.3rem;
      border: 0.3rem solid white;
      border-left: 0.3rem solid ${color.fontColor};
      margin-right: 16px;
      margin-left: 16px;
      content: "";
    }

    .card-link:hover {
      opacity: 0.6;
    }

    .message-alert {
      padding: 16px;
      font-size: 0.9rem;
      border-radius: 8px;
      background: #ffeba580;
      color: ${color.primary};
    }

    h2 > .heading-link {
      top: 6px;
    }

    h3 > .heading-link {
      top: 4px;
    }
  `,
});

const Content: React.FC<{ html: string }> = ({ html }) => {
  return (
    <div css={style.container} dangerouslySetInnerHTML={{ __html: html }}></div>
  );
};

export default Content
