import React from "react";
import { css } from "@emotion/react";
import { Link, graphql, PageProps } from "gatsby";
import Layout from "templates/Layout";
import Sidebar from "templates/docs/Sidebar";
import { color } from "src/constants";
import Icon from "src/components/atoms/Icon";
import Content from "src/components/organisms/Content";
import Breadcurmbs from "src/components/molecules/Breadcrumbs";

const style = Object.freeze({
  container: css`
    padding: 32px 64px;
    height: 100vh;
    overflow-y: scroll;
  `,
  headings: css`
    padding: 16px;

    ul {
      position: sticky;
      top: 0;
      margin-top: 32px;
      padding: 16px;
      padding-left: 32px;
      width: 300px;
      min-height: 400px;
      border-left: 1px solid ${color.fontColor}20;
    }

    li {
      margin-top: 8px;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 32px;
    }

    span {
      font-size: 0.9rem;
      cursor: pointer;
      color: ${color.fontColor}80;
      transition: all 0.2s linear;
    }

    span:hover {
      color: ${color.primary};
    }
  `,
  h2: css``,
  h3: css`
    padding-left: 16px;
  `,
  main: css`
    display: flex;
    justify-content: space-evenly;
    letter-spacing: 0.6px;
  `,
  content: css`
    max-width: 640px;
  `,
  breadcurmbs: css`
    min-height: 64px;
  `,
  header: css`
    margin-bottom: 64px;

    h1 {
      font-weight: bold;
      font-size: 1.8rem;
      letter-spacing: 1px;
    }
  `,
  footer: css`
    display: flex;
    margin-top: 80px;
    justify-content: space-between;
  `,
  node: css`
    border: 1px solid ${color["gray-1"]};
    box-shadow: 1px 3px 10px 2px #44444430;
    padding: 16px;
    margin-bottom: 8px;
    min-width: 300px;
    width: 300px;
    color: ${color.fontColor};
    transition: all 0.2s linear;

    a {
      display: flex;
      align-items: center;
      color: ${color.fontColor};
    }

    a.left {
      justify-content: flex-start;
    }

    a.right {
      justify-content: flex-end;
    }

    p {
      padding: 0;
      margin: 0;
    }
  `,
  hidden: css`
    opacity: 0;
  `,
});

const labels = {
  docs: "ドキュメント",
  introduction: "導入",
  useage: "使い方",
  usecases: "ユースケース",
};

const truncate = (str: string, limit: number = 18) =>
  str.slice(0, limit) + (str.length > limit ? "..." : "");

const IndexPage: React.FC<PageProps<any, any>> = ({ data, pageContext }) => {
  const { markdownRemark } = data;
  const { frontmatter, headings, html } = markdownRemark;
  const { title } = frontmatter;
  const { breadcurmbs, next, previous } = pageContext;

  return (
    <Layout sidebar={<Sidebar />}>
      <div css={style.container}>
        <div css={style.main}>
          <div css={style.content}>
            <div css={style.breadcurmbs}>
              <Breadcurmbs data={breadcurmbs} labels={labels} />
            </div>
            <div css={style.header}>
              <h1>{title}</h1>
            </div>
            <Content html={html} />
            <div css={style.footer}>
              <div css={[style.node, previous || style.hidden]}>
                {previous && (
                  <Link className="left" to={previous.frontmatter.slug}>
                    <p>
                      <Icon
                        css={css`
                          margin-right: 16px;
                        `}
                        size="18px"
                        icon="arrowleft"
                      />
                    </p>
                    <p>{truncate(previous.frontmatter.title)}</p>
                  </Link>
                )}
              </div>
              <div css={[style.node, next || style.hidden]}>
                {next && (
                  <Link className="right" to={next.frontmatter.slug}>
                    <p>{truncate(next.frontmatter.title)}</p>
                    <p>
                      <Icon
                        css={css`
                          margin-left: 16px;
                        `}
                        size="18px"
                        icon="arrowright"
                      />
                    </p>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div css={style.headings}>
            <ul>
              <h2>目次</h2>
              {headings.map((item: any) => {
                if (item.depth > 3) {
                  return null;
                }
                const css = style[`h${item.depth}` as "h2" | "h3"];
                return (
                  <li key={item.value} css={css}>
                    <span
                      onClick={() => {
                        const el = document.querySelector(`#${item.value}`);
                        if (!el) {
                          return;
                        }
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }}
                    >
                      {item.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      headings {
        id
        value
        depth
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
