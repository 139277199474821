import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { color } from "src/constants";
import Icon from "atoms/Icon";

const style = Object.freeze({
  container: css`
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    a {
      color: ${color.fontColor};
    }

    a:hover {
      opacity: 0.6;
    }

    li {
      margin-right: 8px;
    }
  `,
});

interface Props {
  data: string[];
  labels: { [key: string]: string };
}

const Breadcurmbs: React.VFC<Props> = ({ data, labels }) => {
  return (
    <ul css={style.container}>
      {data.map((item: string, index: number) => {
        const path = index !== 0 ? data.slice(0, index + 1).join("/") : "/";
        return (
          <React.Fragment key={path}>
            <li>
              <Link to={path} key={path}>
                {index !== 0 ? labels[item as keyof typeof labels] : "トップ"}
              </Link>
            </li>
            <li>
              <Icon
                css={css`
                  position: relative;
                  top: 1px;
                `}
                icon="right"
                size="10px"
              />
            </li>
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export default Breadcurmbs;
